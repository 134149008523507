import CenterLayout from "@components/centerlayout"
import Box from "@ui/Box"
import Text from "@ui/Text/Text"
import { rem } from "polished"
import React from "react"

import { DividerIllustration } from "../components/DividerIllustration"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Parallax, { ImgTitle, ImgCopyright } from "@components/parallax"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
interface Props {
  data: {
    tackleClimateChange: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

const BackgroundPage = ({ data }: Props) => (
  <Layout>
    <SEO title="Background" />
    <DividerIllustration loading="eager" />
    <CenterLayout my="5xl">
      <Box maxWidth="80ch">
        <Text size="md" as="h1" fontWeight="bold" mb="lg">
          Background
        </Text>
        <Text size="2xl" as="h2" fontWeight="bold" mb="xl">
          In 2015, African countries, together with other members of the United
          Nations General Assembly, adopted the 2030 Agenda for Sustainable
          Development and committed to realising its ambitious 17 Sustainable
          Development Goals (SDGs).
        </Text>
        <Text as="p" mb="md">
          They also pledged to ‘Leave No One Behind’ (LNOB) and ‘to reach the
          furthest behind first’ in the pursuit of sustainable development.
          Earlier that year, in January, the Heads of State and Government of
          the African Union had adopted Agenda 2063, which responds to the
          continent’s specific development challenges through seven aspirations
          and 20 goals.
        </Text>
        <Text as="p" mb="md">
          Three years later, the African Union and the United Nations signed the
          AU-UN Framework for the Implementation of Agenda 2063 and the 2030
          Agenda for Sustainable Development, at the 30th African Union Summit.
          The joint AU-UN sustainable development framework envisions stronger
          coordination between the two organizations to ensure that the UN’s
          2030 Agenda and the AU’s Agenda 2063 are mainstreamed and integrated
          into the national planning frameworks of Member States and contribute
          positively to people-centred and planet-sensitive structural
          transformation in Africa.
        </Text>
        <Text size="2xl" as="h2" fontWeight="bold" my="xl">
          As signatories to both agendas, African countries find themselves
          having to simultaneously implement two bold initiatives.
        </Text>
        <Text as="p" mb="md">
          While a longstanding tradition of Poverty Reduction Strategy Papers
          (PRSPs) and Management for Development Results (MfDR) have helped to
          strengthen capacity for national planning and budgeting processes in
          several African countries, the traditional planning approaches and
          tools available to countries have been proven to be inadequate for
          addressing the need to reflect international commitments, such as the
          SDGs, Agenda 2063, the Paris Agreement (Nationally Determined
          Contributions (NDCs) and other priority development issues in
          countries’ planning and policy frameworks.
        </Text>
        <Text size="2xl" as="h2" fontWeight="bold" my="xl">
          This has generated a demand for new tools, approaches and
          institutional capacity support for planning in an integrated way that
          not only mainstreams the global and continental commitments that
          countries made, but also takes into consideration the inter-sectoral
          trade-offs and synergies.
        </Text>
        <Text as="p" mb="md">
          In the wake of the COVID-19 pandemic, countries are also demanding new
          planning tools that help to explore probable future scenarios and
          adequately plan for them. The conclusion of the Paris Agreement on
          Climate Change in December 2015 rekindled hope on a collective,
          cooperative and genuine global effort to tackle climate change.
        </Text>
        <Text as="p" mb="md">
          To maintain this political momentum countries will strive to scale up
          adaptation action and raise their ambition to reduce emissions.
          African countries, along with the rest of the world, submitted
          national climate commitments known as Nationally Determined
          Contributions (NDCs); NDCs should be aligned to national priorities
          and implemented in the context of sustainable development as guided by
          the 2030 Agenda and its ambitious 17 Sustainable Development Goals
          (SDGs). In the same year, countries adopted the Sendai Framework on
          Disaster Risk Reduction to protect their hard-won development gains.
        </Text>
        <Text size="2xl" as="h2" fontWeight="bold" my="xl">
          The need to jointly mainstream the global and the continental agendas
          into national policies and plans, together with agendas related to
          climate change, disaster risk, LNOB, peace and security pose enormous
          challenges.
        </Text>
        <Text as="p" mb="md">
          The need to jointly mainstream the global and the continental agendas
          into national policies and plans, together with agendas related to
          climate change, disaster risk, LNOB, peace and security pose enormous
          challenges. These are compounded by systemic risks posed by the
          so-called ‘Black Swans’ – unpredictable events with major consequences
          for national economies, such as the COVID-19 and other pandemics.
        </Text>
        <Text size="2xl" as="h2" fontWeight="bold" my="xl">
          To respond to these demands, the UNDP Regional Bureau for Africa has
          partnered with the African Development Bank (AfDB) within the
          framework of the Africa NDC Hub, the African Union Development Agency-
          New Partnership for Africa’s Development (AUDA-NEPAD) and the United
          Nations Economic Commission for Africa (UNECA), to develop the current
          guide,
        </Text>
        <Text as="p" mb="md">
          with the objective to support planners in African countries to develop
          a new generation of national development plans that mainstream the
          SDGs, AU Agenda 2063, the Paris Agreement/NDCs, the Sendai Framework
          for Disaster Risk Reduction, Peace and Justice, and gender equality)
          as a key catalyst for economic development in the continent.
        </Text>
      </Box>
    </CenterLayout>
    <Parallax
      scaleFactor={1.25}
      backgroundProps={{
        image: data.tackleClimateChange.childImageSharp.gatsbyImageData,
        alt: "Plans to tackle climate change",
      }}
    >
      <CenterLayout py={rem("224px")}>
        <ImgTitle>Plans to tackle climate change</ImgTitle>
        <ImgCopyright>UNDP South Sudan. Photo: Louis Fourmentin</ImgCopyright>
      </CenterLayout>
    </Parallax>
  </Layout>
)

export const pageQuery = graphql`
  {
    tackleClimateChange: file(
      relativePath: { eq: "tackle-climate-change.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { fit: COVER, cropFocus: ATTENTION }
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default BackgroundPage
